<template>
  <v-container>
    <v-row class="" justify="center">
      <v-col cols="12">
        <v-card class="grey lighten-3 rounded mt-4">
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="questionObj.user.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{questionObj.user.full_name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{questionObj.user.username}} | {{questionObj.room.sections}}
              </v-list-item-subtitle>
            </v-list-item-content>
            
            <v-list-item-content>
              <v-list-item-subtitle align="right">
                {{questionObj.last_edited ?
                  moment(questionObj.last_edited).fromNow() :
                  moment(questionObj.created).fromNow()}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-title>
            {{ questionObj.title }}
          </v-card-title>
          <v-card-subtitle v-if="questionObj.description">
            {{ questionObj.description }}
          </v-card-subtitle>

          <!-- question images -->
          <v-card-text>
            <v-row
              dense
              v-if="questionObj.question_images && questionObj.question_images.length"
            >
              <v-col
                cols="4"
                md="3"
                v-for="image in questionObj.question_images"
                :key="image.id"
              >
                <v-card outlined elevation="0">
                  <v-img
                    class="cursor-pointer"
                    @click="showFullScreenCarousel(image, questionObj.question_images)"
                    :src="image.image"
                    aspect-ratio="1"
                  >
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mx-2">
            <v-row>
              <v-col cols="12" md="10">
                <v-chip outlined v-if="questionObj.asked_to.length == 1" @click="$set(questionObj, 'showAskedToList', true)" small class="mr-2" color="green">
                  Asked to: {{ questionObj.asked_to[0].full_name }}
                </v-chip>
                <v-chip outlined v-else-if="questionObj.asked_to.length > 1" @click="$set(questionObj, 'showAskedToList', true)" small class="mr-2" color="green">
                  Asked to: {{ questionObj.asked_to[0].full_name }} & {{ questionObj.asked_to.length - 1}} others
                </v-chip>
                <v-chip outlined small color="green">
                  {{ questionObj.answers.length}} answers
                </v-chip>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  block
                  v-if="currentUser.is_faculty || currentUser.is_admin"
                  color="primary"
                  elevation="1"
                  small
                  class="mb-1 non-focused"
                  @click="openAnswerSheet(questionObj)"
                > Answer
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

        <div v-if="questionObj.answers" class="black--text caption-1 mt-4">
          <v-divider></v-divider>
          <v-list v-for="answer in questionObj.answers" :key="answer.id">
            <v-card elevation="0" class="green lighten-5 rounded">
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="answer.user.image"> </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ answer.user.full_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ answer.user.username }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content>
                <v-list-item-subtitle align="right">
                  {{answer.last_edited ?
                    moment(answer.last_edited).fromNow() :
                    moment(answer.created).fromNow()}}
                </v-list-item-subtitle>
              </v-list-item-content>

                <v-list-item-icon 
                  v-if="answer.user.id == currentUser.id || currentUser.is_admin"
                  class="align-self-center mr-n3">
                  <intract-drop-down-menu
                    :options-list="getAnswerDropdownOptions(answer)"
                  />
                </v-list-item-icon>
              </v-list-item>

              <v-card-text class="mt-n5">
                <div class="black--text subtitle-1">
                  {{ answer.answer_text }}
                </div>
                <!-- answer images -->
                <v-row
                  class="mt-2"
                  dense
                  v-if="answer.answer_images && answer.answer_images.length"
                >
                  <v-col
                    cols="4"
                    md="3"
                    v-for="image in answer.answer_images"
                    :key="image.id"
                  >
                    <v-card outlined elevation="0">
                      <v-img
                        class="cursor-pointer"
                        @click="showFullScreenCarousel(image, answer.answer_images)"
                        :src="image.image"
                        aspect-ratio="1"
                      >
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list>
        </div>
      </v-col>
    </v-row>
  
    <!-- Asked To List -->
    <intract-list-sheet
      title="Asked to"
      description="The question is asked to the following faculty."
      :visible="questionObj.showAskedToList"
      :list="showAskedToFaculty(questionObj)"
      @close="$set(questionObj, 'showAskedToList', false)">
    </intract-list-sheet>

    <!-- QUESTION EDIT SHEET -->
    <intract-create-edit-sheet
      persistent
      title="Edit Question"
      submit-button-text="Edit"
      :visible="questionEditSheet.visible"
      @close="questionEditSheet.visible = false; questionEditSheet.editId = null"
      :fields="questionformFields"
      :endpoint="endpoints.questionViewSet"
      enable-media
      :edit-id="questionEditSheet.editId"
      :edit-success-message="questionEditSheet.editSuccessMessage"
      :data-object="questionEditSheet.obj"
      @objectCreated="questionEdited"
      @updateObject="(obj) => (questionEditSheet.obj = obj)"
    />

    <!-- ANSWER CREATE EDIT SHEET -->
    <intract-create-edit-sheet
      persistent
      :title="
          answerCreateEditSheet.editId
            ? 'Edit Answer'
            : 'Answer Question'        
      "
      :submit-button-text="
          answerCreateEditSheet.editId
            ? 'Edit'
            : 'Answer'
      "
      :visible="answerCreateEditSheet.visible"
      @close="answerCreateEditSheet.visible = false; answerCreateEditSheet.editId = null"
      :fields="answerformFields"
      :endpoint="endpoints.answerViewSet"
      enable-media
      :edit-id="answerCreateEditSheet.editId"
      :edit-success-message="answerCreateEditSheet.editSuccessMessage"
      :data-object="answerCreateEditSheet.obj"
      :create-success-message="answerCreateEditSheet.createSuccessMessage"
      @objectCreated="answerCreatedOrEdited"
      @updateObject="(obj) => (answerCreateEditSheet.obj = obj)"
    />

    <full-screen-image-carousel
      :initial-image="initialImage"
      :images="fullScreenCarouselImages"
      :visible="fullScreenImageOverlay"
      @closeDialog="fullScreenImageOverlay = false"
    />

    <confirmation-dialog
      title="Are you sure you want to delete this Question?"
      description="This action cannot be undone" 
      :visible="showDeleteQuestionDialog"
      delete-dialog
      @successCallback="deleteQuestion"
      @failureCallback="showDeleteQuestionDialog = false"
    />

    <confirmation-dialog
      title="Are you sure you want to delete this answer?"
      description="This action cannot be undone" 
      :visible="showDeleteAnswerDialog"
      delete-dialog
      @successCallback="deleteAnswer"
      @failureCallback="showDeleteAnswerDialog = false"
    />  

  </v-container>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractListSheet from "@components/generics/IntractListSheet";
import IntractCreateEditSheet, { CreateEditFieldTypes,} from "@components/generics/IntractCreateEditSheet.vue";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import FullScreenImageCarousel from "@components/dialogs/FullScreenImageCarousel";

import moment from "moment";
export default {
  name: "Question",
  mixins: [Mixins.essentials, Mixins.handleAppBarTitle, Mixins.handleAppBarOptions],
  components: {
    IntractDropDownMenu,
    IntractCreateEditSheet,
    IntractListSheet,
    FullScreenImageCarousel
  },
  data() {
    return {
      moment: moment,
      appBarTitle: null,
      questionLoaded: false,
      questionId: this.$route.params.questionId,
      questionObj: { user: {}, room:{}, asked_to:[], answers:[] },
      showAskedToList: false,
      showDeleteQuestionDialog: false,
      showDeleteAnswerDialog: false,
      toBeDeletedAnswer: null,
      initialImage: null,
      fullScreenImageOverlay: false,
      fullScreenCarouselImages: [],
      questionEditSheet: {
        visible: false,
        editSuccessMessage: "Question edited successfully!",
        editId: null,
        obj: {
            room: {},
            institution: null,
            user: {},
            answer: [],
            question_images: [],
        },
      }, 
      answerCreateEditSheet: {
        visible: false,
          createSuccessMessage: "Answer posted successfully!",
          editSuccessMessage: "Answer edited successfully!",
          editId: null,
          obj: {
              user: {},
              question: {},
              answer_images: [],
        },
      }, 
    };
  },
  computed: {
    appBarOptions() {
      const self = this;
      return {
        actions: [
          ...(self.currentUser.is_student && self.questionLoaded && self.questionObj.user.id != self.currentUser.id) ? 
          [] :
          [
            {
              value: 1, 
              title: "Edit", 
              icon: "mdi-pencil",
              onClick: () => {
                self.questionEditSheet.obj.user = self.questionObj.user.id;
                self.questionEditSheet.obj.room = self.questionObj.room.id;
                self.questionEditSheet.obj.institution = self.currentInstitution.id;
                self.questionEditSheet.editId = self.questionObj.id;
                self.questionEditSheet.visible = true;
              }                  
            }, 
            {
              value: 2,
              title: "Delete",
              icon: "mdi-delete",
              onClick: () => {
                this.showDeleteQuestionDialog = true;
              }          
            }
          ],
        ]
      }
    },

    questionformFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description (optional)",
          md: 12,
          required: false,
          max: 3000,
        },
        asked_to: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Add Teachers",
          required: false,
          md: 12,
          endpoint: this.Helper.addUrlParams(
            this.endpoints.staffViewSet,
            "paginate=false"
          ),
          multiple: true,
          itemValue: "id",
          itemText: "full_name",
          items: [],
        },
        question_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
      };
    },

    answerformFields() {
      return {
        answer_text: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Answer",
          md: 12,
          required: true,
          max: 3000,
        },
        answer_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        }
      };
    },
  },

  methods: {
    showAskedToFaculty(questionObj) {
      return questionObj.asked_to.map(faculty => { 
        return {
          image: faculty.image,
          title: faculty.full_name,
          subtitle: faculty.username,
        };               
      })
    },

    async showFullScreenCarousel(image, imageList) {
      this.initialImage = image;
      this.fullScreenImageOverlay = true;
      this.fullScreenCarouselImages = imageList;
    },

    async questionEdited(questionObj) {
      this.questionObj = questionObj;
    },

    async deleteQuestion() {
      var url = this.endpoints.questionViewSet + this.questionObj.id + "/";
      var response = await this.api.call(
        this.essentials,
        url, 
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Question has been successfully deleted!",
          type: "success",
        });
      }  
      this.$router.back();       
    },

    openAnswerSheet(questionObj){
      this.answerCreateEditSheet.obj.user = this.currentUser.id;
      this.answerCreateEditSheet.obj.question = questionObj.id; 
      this.answerCreateEditSheet.obj.institution = this.currentInstitution.id;
      this.answerCreateEditSheet.visible = true;
    },

    async answerCreatedOrEdited(answerObj) {
      if (this.questionObj.answers) {
        var isEditing = this.answerCreateEditSheet.editId != null;
        if(isEditing) {
          // editing
          // question.answers = [{...},{}]
          // find the answer obj
          var answer = this.questionObj.answers.find(ans => ans.id == answerObj.id);
          // replace the answer_text
          if(answer) {
            // answer.answer_text = answerObj.answer_text;
            // answer.answer_images = answerObj.answer_images;
            Object.assign(answer, answerObj);
          }
        }
        else this.questionObj.answers.unshift(answerObj);
      }
    },

    getAnswerDropdownOptions(answer) {
      var self = this;
      return [
        {
          value: 1, 
          text: "Edit", 
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.answerCreateEditSheet.obj.question = answer.question;
            self.answerCreateEditSheet.obj.user = answer.user.id;
            self.answerCreateEditSheet.editId = answer.id;
            self.answerCreateEditSheet.visible = true;
          }                  
        }, 
        {
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedAnswerId = answer.id;
            self.showDeleteAnswerDialog = true;
          }          
        }
      ];
    },

    async deleteAnswer() {
      var url = this.endpoints.answerViewSet + this.toBeDeletedAnswerId+ "/";
      this.showDeleteAnswerDialog = false;
      var response = await this.api.call(
        this.essentials,
        url, 
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Answer has been successfully deleted!",
          type: "success",
        });
        this.questionObj.answers = this.questionObj.answers.filter(ans => ans.id != this.toBeDeletedAnswerId);
      }
      this.toBeDeletedAnswerId = null;
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  async created() {
    await this.api.call(
      this.essentials,
      this.endpoints.questionViewSet + this.questionId + '/',
      this.api.Methods.GET
    ).then((response) => {
      this.questionObj = response;
      this.questionLoaded = true;
      if (this.questionObj == null) throw "error";
    }).catch(() => {
      this.showSnackbar({
        text: "Question doesn't exist.",
        type: "error",
      })
    });
    this.appBarTitle = this.questionObj.title;
  },
};
</script>

<style>
  .custom-font-size-small {
    font-size:13px;
    text-align: right;
  }
</style>